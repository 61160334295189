/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react';
import { IoClose, IoMenu } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import ButtonTopBar from '../Button/ButtonTopBar';
import './styles.css';

export default function TopBar({
  sections,
  language,
  setLanguage,
  eng,
  esp,
}) {
  const location = useLocation();

  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleChange = () => {
    setLanguage(language === eng ? esp : eng);
  };

  return (
    <nav className={clicked && 'navHome'}>
      <div className="logo">
        <a
          href="https://sergiodiaz.dev"
          title="Personal Page"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p style={{ color: 'white' }}>Sergio Diaz</p>
        </a>
      </div>
      <div className="buttonsContainer">
        <ul id="navbar" className={clicked ? '#navbar active' : '#navbar'}>
          {sections.map((section) => (
            <ButtonTopBar
              key={section.id}
              text={section.label}
              active={section.route === location.pathname}
              url={section.route}
            />
          ))}
        </ul>
        <div className="btn-container">
          <label className="switch btn-color-mode-switch">
            <input
              type="checkbox"
              id="color_mode"
              name="color_mode"
              value="1"
              checked={language !== eng}
              onChange={handleChange}
            />
            <label className="btn-color-mode-switch-inner" data-off="En" data-on="Es" for="color_mode" />
          </label>
        </div>
      </div>
      <div
        id="mobile"
        onClick={handleClick}
        onKeyDown={handleClick}
        aria-hidden="true"
      >
        <i id="bar" className={clicked ? 'fas fa-times' : 'fas fa-bars'}>
          {clicked ? <IoClose /> : <IoMenu />}
        </i>
      </div>
    </nav>
  );
}
