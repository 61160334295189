/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { IoAdd } from 'react-icons/io5';
import './styles.css';

export default function CardResume({ information, jobs }) {
  const [handleRadio] = useState('experience');

  const [selectedJob, setSelectedJob] = useState(jobs[0]);
  const [modalOpen, setModalOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setModalOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  function calculateDates(initialDate, finalDate = moment()) {
    const begin = moment(initialDate, 'YYYY/MM/DD');
    const final = moment(finalDate, 'YYYY/MM/DD');

    const diff = final.diff(begin, 'months');
    const years = Math.floor(diff / 12);
    const months = (diff % 12) + (final.date() >= begin.date() ? 1 : 0);

    const yearSuffix = years === 1 ? information.resume.year : information.resume.years;
    const monthSuffix = months === 1 ? information.resume.month : information.resume.months;

    let formattedDate = '';

    if (years > 0) {
      formattedDate += `${years} ${yearSuffix}`;
    }

    if (months > 0) {
      formattedDate += `${years > 0 ? ' - ' : ''}${months} ${monthSuffix}`;
    }

    return formattedDate;
  }

  function formatDateRange(beginDate, endDate) {
    const begin = new Date(beginDate);
    const end = endDate ? new Date(endDate) : new Date();

    const beginMonth = information.months[begin.getMonth()];
    const beginYear = begin.getFullYear();
    const endMonth = information.months[end.getMonth()];
    const endYear = end.getFullYear();

    if (beginYear === endYear) {
      if (beginMonth === endMonth) {
        return `${beginMonth} ${beginYear}`;
      }
      if (endDate) {
        return `${beginMonth} - ${endMonth} ${beginYear}`;
      }
      return `${beginMonth} - ${information.resume.current}`;
    }
    if (endDate) {
      return `${beginMonth} ${beginYear} - ${endMonth} ${endYear}`;
    }
    return `${beginMonth} ${beginYear} - ${information.resume.current}`;
  }

  return (
    <div className="card-resume">
      <div className="title-holder-resume">
        <h1 className="title">{information.resume.title}</h1>
      </div>

      <div className="content-resume">
        {handleRadio === 'experience' && !modalOpen && (
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2">
                <ul className="timeline timeline-centered">
                  {jobs.map((job) => (
                    <li className="timeline-item" key={job.id}>
                      <div className="timeline-info">
                        <span>
                          {formatDateRange(job.beginDate, job.endDate)}
                        </span>
                        <span>
                          {calculateDates(job.beginDate, job.endDate)}
                        </span>
                      </div>
                      <div className="timeline-marker" />
                      <div className="timeline-content">
                        <h3 className="timeline-title">{job.company}</h3>
                        <h3 className="timeline-subtitle">{job.jobTitle}</h3>
                        {job.description.map((description) => (
                          <p key={job.id}>{`• ${description}`}</p>
                        ))}
                        <button
                          type="button"
                          className="button-more"
                          data-text={information.resume.learn}
                          onClick={() => {
                            setSelectedJob(job);
                            setModalOpen(!modalOpen);
                          }}
                        >
                          <IoAdd className="svgIcon-more" />
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}

        {handleRadio === 'education' && (
          <div className="container-fluid">
            <div className="row example-centered">
              <p>EDUCACION</p>
            </div>
          </div>
        )}

        {handleRadio === 'aptitude' && (
          <div className="container-fluid">
            <div className="row example-centered">
              <p>APTITUDES</p>
            </div>
          </div>
        )}

        {modalOpen && (
          <div
            ref={ref}
            className="card fadeInUp"
            style={{ animationDelay: `${0.5 * 0.1}s` }}
          >
            <div className="tools">
              <div
                className="circle"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                <span className="red box" />
              </div>
            </div>
            <div className="card__content">
              <div className="inner">
                <div>
                  <p className="companyName">{selectedJob?.company}</p>
                  <p className="jobTitle">{selectedJob?.jobTitle}</p>
                  <p className="dates">
                    {formatDateRange(
                      selectedJob?.beginDate,
                      selectedJob?.endDate,
                    )}
                  </p>
                  <p className="time-elapsed">
                    {calculateDates(
                      selectedJob?.beginDate,
                      selectedJob?.endDate,
                    )}
                  </p>
                </div>
                <div>
                  <img
                    src={require(`../../../assets/images/companyLogos/${selectedJob?.logo}.png`)}
                    alt="user"
                    style={{ width: '14vh' }}
                  />
                </div>
              </div>
              <div className="bundleDescription">
                <p className="description">{selectedJob?.fullDescription}</p>
              </div>

              <p className="chipsBundleTitle">{information.resume.KS}</p>

              <div className="chipsBundle">
                {selectedJob?.tools?.map((tool, index) => (
                  <div className="chip" key={index}>
                    <div className="chip-content">{tool}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
