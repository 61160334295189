import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import English from './assets/data/english.json';
import Spanish from './assets/data/spanish.json';
import CardAbout from './components/Cards/CardAbout/CardAbout';
import CardHome from './components/Cards/CardHome/CardHome';
import CardResume from './components/Cards/CardResume/CardResume';
import TopBar from './components/TopBar/TopBar';

function App() {
  const [language, setLanguage] = useState(English);

  const sections = [
    {
      id: 0,
      label: language.generalInformation.navBar.home,
      route: '/',
      element: <CardHome information={language.personalInformation} />,
    },
    {
      id: 1,
      label: language.generalInformation.navBar.about,
      route: '/about',
      element: <CardAbout
        title={language.generalInformation.about.title}
        information={language.personalInformation}
      />,
    },
    {
      id: 2,
      label: language.generalInformation.navBar.resume,
      route: '/resume',
      element: <CardResume
        information={language.generalInformation}
        jobs={language.jobs}
      />,
    },
  ];

  return (
    <div className="generalDiv">
      <TopBar
        sections={sections}
        language={language}
        setLanguage={setLanguage}
        eng={English}
        esp={Spanish}
      />
      <Routes>
        <Route path="/" element={<CardHome information={language.personalInformation} />} />
        {sections.map((section) => (
          <Route
            key={section.id}
            path={section.route}
            element={section.element}
          />
        ))}
        <Route path="*" element={<CardHome />} />
      </Routes>
    </div>
  );
}

export default App;
