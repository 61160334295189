import React from 'react';
import { IoLogoGithub, IoLogoLinkedin, IoMail } from 'react-icons/io5';
import './styles.css';

export default function CardHome({ information }) {
  return (
    <div className="cardHome">
      <div className="contentHome">
        <div className="title-holderHome">
          <h1 className="title">{information.name}</h1>
          <h1>{information.jobTitle}</h1>
        </div>
        <div className="socialSection">
          <a href={information.linkedinURL} title="Linkedin" target="_blank" rel="noopener noreferrer">
            <IoLogoLinkedin />
          </a>
          <a href={information.githubURL} title="Github" target="_blank" rel="noopener noreferrer">
            <IoLogoGithub />
          </a>
          <a href={`mailto:${information.mail}`} title="Mail" target="_blank" rel="noopener noreferrer">
            <IoMail />
          </a>
        </div>
      </div>
    </div>
  );
}
