import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';

export default function ButtonTopBar({ text, active, url }) {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className={`buttonTopBar ${active ? 'active' : ''}`}
      onClick={() => navigate(url)}
    >
      <span className="circle1" />
      <span className="circle2" />
      <span className="circle3" />
      <span className="circle4" />
      <span className="circle5" />
      <span className="text">{text || ''}</span>
    </button>
  );
}
