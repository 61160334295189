/* eslint-disable react/no-danger */
import React from 'react';
import memoji from '../../../assets/images/memoji_square.png';
import './styles.css';

export default function CardAbout({ information, title }) {
  return (
    <div className="card-about">
      <div className="title-holder-about">
        <h1 className="title">{title}</h1>
      </div>

      <div className="aboutContainer">
        <div className="container-Profile">
          <div className="card-Profile">
            <div className="card-header-Profile">
              <div className="profile-images">
                <img
                  src={memoji}
                  alt="user"
                />
              </div>
              <div className="profileText">
                <div className="profile-name"><h1>{information.name}</h1></div>
                <div className="profile-role"><h1>{information.jobTitle}</h1></div>
              </div>
            </div>
          </div>
        </div>

        <div className="aboutDescription">
          <p dangerouslySetInnerHTML={{ __html: information.description }} />
        </div>

      </div>
    </div>
  );
}
